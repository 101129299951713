precision highp float;

varying vec2 vUv;
uniform float time;
uniform sampler2D image;
uniform vec2 mouseCoords;

#define PI 3.14159265359

#pragma glslify: noise = require('glsl-noise/simplex/3d')
// #pragma glslify: fxaa = require(glsl-fxaa)
#pragma glslify: hsl2rgb = require(glsl-hsl2rgb)


float random(vec2 c){
    return fract(sin(dot(c.xy ,vec2(12.9898,78.233))) * 43758.5453);
}

const float interval = 5.;

void main() {
    // vec2 uv = vUv;

    // float n = noise(vec3(time * .6, uv.y + time * 0.1, 0.2 * noise(vec3(time * 0.6, uv.y, uv.x + time * 0.01))));
    // gl_FragColor = texture2D(image, vec2(uv.x + n * 0.1 * (1.0 - uv.y), uv.y));

    vec2 st = vUv;

    float strength = smoothstep(interval * 0.5, interval, interval - mod(time * 0.001, interval)) * 0.0001;
    vec2 shake = vec2(strength * 8.0 + 0.5) * vec2(
        random(vec2(time * 0.001)) * 2.0 - 1.0,
        random(vec2(time * 0.002)) * 2.0 - 1.0
    ) * 0.01;
    
    float y = st.y;
    float rgbWave = (
        noise(vec3(0.0, y * 0.01, time * .004)) * (2.0 + strength * 32.0)
        * noise(vec3(0.0, y * 0.02, time * .002)) * (1.0 + strength * 4.0)
        + step(0.9995, sin(y * 0.005 + time * 0.016)) * 12.0
        + step(0.9999, sin(y * 0.005 + time * 0.02)) * -18.0
    ) * 0.1;

    float rgbDiff = (6.0 + sin(time * 500.0 + st.y * 40.0) * (20.0 * strength + 1.0)) * 0.0004;
    float rgbUvX = st.x + rgbWave;

    float r = texture2D(image, vec2(rgbUvX + rgbDiff, st.y) + shake).r ;
    float g = texture2D(image, vec2(rgbUvX, st.y) + shake).g;
    float b = texture2D(image, vec2(rgbUvX - rgbDiff, st.y) + shake).b;
    
    float whiteNoise = (random(st + mod(time, 10.0)) * 2.0 - 1.0) * (0.15 + strength * 0.15);
    
    float bntime = floor(time * 20.0) * 200.0;
    float noiseX = step((noise(vec3(0.0, st.x * 3.0, bntime)) + 1.0) / 2.0, 0.12 + strength * 0.3);
    float noiseY = step((noise(vec3(0.0, st.y * 3.0, bntime)) + 1.0) / 2.0, 0.12 + strength * 0.3);
    float bnMask = noiseX * noiseY;
    float bnUvX = st.x + sin(bntime) * 0.2 + rgbWave;
    float bnR = texture2D(image, vec2(bnUvX + rgbDiff, st.y)).r * bnMask;
    float bnG = texture2D(image, vec2(bnUvX, st.y)).g * bnMask;
    float bnB = texture2D(image, vec2(bnUvX - rgbDiff, st.y)).b * bnMask;
    vec4 blockNoise = vec4(bnR, bnG, bnB, 1.0) ;
    
    float bntime2 = floor(time * 25.0) * 300.0;
    float noiseX2 = step((noise(vec3(0.0, st.x * 2.0, bntime2)) + 1.0) / 2.0, 0.12 + strength * 0.5);
    float noiseY2 = step((noise(vec3(0.0, st.y * 8.0, bntime2)) + 1.0) / 2.0, 0.12 + strength * 0.3);
    float bnMask2 = noiseX2 * noiseY2;
    float bnR2 = texture2D(image, vec2(bnUvX + rgbDiff, st.y)).r * bnMask2;
    float bnG2 = texture2D(image, vec2(bnUvX, st.y)).g * bnMask2;
    float bnB2 = texture2D(image, vec2(bnUvX - rgbDiff, st.y)).b * bnMask2;
    vec4 blockNoise2 = vec4(bnR2, bnG2, bnB2, 1.0);
    
    float waveNoise = (sin(st.y * 1000.0) + 1.0) / 2.0 * (0.15 + strength * 0.5);
    
    vec4 glitch = vec4(r, g, b, 1.0) * (1.0 - bnMask - bnMask2) + (whiteNoise + blockNoise + blockNoise2 - waveNoise);

    float md = clamp(distance(st * 1.5, mouseCoords * 1.5), 0.0, 1.0);
    glitch *= 1.0 - md;
    vec4 texture = texture2D(image, st);

    // gl_FragColor = mix(texture, glitch, noise(vec3(0.0, st.y  + time * 2.0, time * 0.002)) * 0.5 + 0.5);
    gl_FragColor = glitch + vec4(vec3((1.0-md) * 0.2), 0.0);
}