import * as UA from '@urpflanze/animation'
import * as cc from 'creative-tools'
import reglimage from './reglimage'

export function sgw_live() {
	if (document.querySelector('.sgw-live')) return

	const audio = new Audio('/assets/blop.wav')

	const sgwStyle = document.createElement('style')
	sgwStyle.innerHTML = `
    @import url('https://fonts.cdnfonts.com/css/roboto');
    .sgw-live{
        position: fixed;
        top: 5%;
        left: 50%;
        transform: translate(-50%, 0%);
        transition: transform .15s ease-out;
        z-index: 3000;
        width: 80vw;
        max-width: 400px;
    }
    .sgw-live:hover {
        transform: translate(-50%, 0%) scale(1.1);
    }
    .sgw-live a {
        opacity: 0;
        display: block;
        width: 100%;
    
        color: #fff;
        text-decoration: none;
    }
    .sgw-live__cover { 
        position:relative;
        line-height: 0;
        margin-bottom: 12px;
    }
    .sgw-live__cover__img {
        border-radius: 12px;
        width: 100%;
    }
    .swg-live__cover__dur {
        position: absolute;
        line-height: 1;
        bottom: 0;
        right: 0;
        margin: 4px;
        color: #fff;
        background-color: rgba(0,0,0,.8);
        padding: 3px 4px 4px;
        font-size: .9rem;
        border-radius: 4px;
        font-family: 'Roboto Medium', sans-serif;
    }
    .sgw-live__footer {
        display: flex;
        align-items: flex-start;
        gap: 8px;
    }
    .sgw-live__footer__thumb {
        width: 36px;
        border-radius: 50%;
    }
    .sgw-live__footer__desc {
        font-family: 'Roboto Medium', sans-serif;
    }
    .sgw-live__footer__desc__small {
        margin-top: 4px;
        font-family: 'Roboto', sans-serif;
        font-size: .8rem;
    }
    `
	document.head.append(sgwStyle)

	const sgwDiv = document.createElement('div')
	sgwDiv.classList.add('sgw-live')
	sgwDiv.innerHTML = `
   <a href="https://youtu.be/boJqmrYgn80"> 
    <div class="sgw-live__cover">
        <canvas class="sgw-live__cover__img" />
        <div class="swg-live__cover__dur">5:41</div>
    </div>
    <div class="sgw-live__footer">
      <div class="sgw-live__footer">
        <img class="sgw-live__footer__thumb" src="assets/sgw-min.jpg" alt="">
        <div class="sgw-live__footer__desc">
            <b>SGW Tapes | BENN - Andare in TV</b>
            <div class="sgw-live__footer__desc__small">
            SGW<br />
            666.666 visualizzazioni
            </div>
        </div>
      </div>
    </div>    
    </a>
    `

	document.body.append(sgwDiv)

	setTimeout(() => {
		const animEl = sgwDiv.getElementsByTagName('a')[0]
		const rect = animEl.parentElement!.getBoundingClientRect()

		const canvas = sgwDiv.getElementsByTagName('canvas')[0] as HTMLCanvasElement
		canvas.width = rect.width
		canvas.height = rect.width / (1080 / 608)
		canvas.style.width = '100%'
		const image = new Image()
		let regl
		image.onload = () => {
			regl = reglimage(canvas, image)
		}
		image.src = 'assets/sgw-live.jpg'

		animEl.style.opacity = '0'
		animEl.style.transform = 'translate(-50%, -50%) scale(.8)'

		// setTimeout(() => {
		// 	regl && regl.setAnimate(true)

		// 	const b = sgwDiv.querySelector('b')
		// 	const text = b?.textContent || ''
		// 	let current = text
		// 	function anim() {
		// 		const splitted = current.split('')
		// 		current.split('').forEach((c, i) => {
		// 			splitted[i] = /^[A-Za-z0-9]*$/.test(c) ? String.fromCharCode((c.charCodeAt(0) + 1) % 100) : c
		// 		})
		// 		current = splitted.join('')
		// 		b!.textContent = current

		// 		setTimeout(() => {
		// 			requestAnimationFrame(anim)
		// 		}, 100)
		// 	}

		// 	requestAnimationFrame(anim)
		// }, 5000)

		const RandM = Math.PI * (0.2 + Math.random() * 1) * (Math.random() > 0.5 ? 1 : -1)
		setTimeout(() => {
			audio.play()
			animEl.style.opacity = '1'

			animate(
				[0, 0],
				[1, 1],
				1000,
				false,
				s => {
					animEl.style.transform = `rotate(${Math.sin((s[0] * 0.5 - 0.5) * RandM) * 10}deg) scale(${s[0]})`
				},
				() => {
					audio.pause()
				}
			)
		}, 2000)
	})
}

function animate(
	from: any,
	to: any,
	duration: number,
	linear: boolean,
	cb: (v: any) => void,
	end: CallableFunction | null = null
) {
	const anim = UA.createAnimation({
		from,
		to,
		duration,
		interpolator: linear
			? 'linear'
			: {
					type: 'elasticOut',
					params: {
						amplitude: 2.6,
						period: 1.2,
					},
			  },
	})!

	const s = cc.now()

	function c() {
		anim.update(cc.now() - s)

		cb(anim.value as any)

		if (!anim.ended) requestAnimationFrame(c)
		else {
			end && end()
		}
	}

	requestAnimationFrame(c)
}
