import * as cc from 'creative-tools'

export default function reglimage(canvas: HTMLCanvasElement, image: HTMLImageElement) {
	const plane = require('primitive-plane')(2, 2, 20, 20)
	// const plane = require('primitive-plane')(1, 1, 2, 2, { quads: true })

	const regl = require('regl')(canvas, {
		pixelRatio: Math.min(window.devicePixelRatio, 1.5),
		attributes: {
			antialias: false,
			stencil: false,
			alpha: true,
			depth: true,
		},
	})

	const frame = regl({
		frag: `precision highp float;

        varying vec2 vUv;
        uniform float time;
        uniform sampler2D image;
        uniform float animate;

        void main() {
            vec2 uv = vUv;

            float freq = 3.0; 
            float sync = 2.0; 
            float offset = 0.0;
            float angle = time * 6.0;
            float speed = 0.0;

            vec2 st = uv;
            vec2 xy = st - vec2(0.5);
            float ang = angle + speed *time;
            xy = mat2(cos(ang),-sin(ang), sin(ang),cos(ang))*xy;
            xy += 0.5;
            st = xy;

            float r = sin((st.x-offset*2.0/freq+time*sync)*freq)*0.5  + 0.5;
            float g = sin((st.x+time*sync)*freq)*0.5 + 0.5;
            float b = sin((st.x+offset/freq+time*sync)*freq)*0.5  + 0.5;
            
            vec4 color = texture2D(image, uv);

            gl_FragColor = color + (vec4(r, g, b, 0.0) * (animate) * 0.5);
        }`,

		vert: `
    precision highp float;
    attribute vec3 position;
    attribute vec2 uv;
    varying vec2 vUv;
   
    
    void main() {
        vUv = uv;
        gl_Position =  vec4(position, 1);
    }`,

		uniforms: {
			time: ({ time }) => time,
			image: regl.prop('image'),
			animate: regl.prop('animate'),
		},

		attributes: {
			position: plane.positions,
			normal: plane.normals,
			uv: plane.uvs,
		},
		elements: plane.cells,
	})

	const texture = regl.texture({ data: image, flipY: true })
	let animate = 0

	regl.frame(function () {
		regl.clear({
			color: [0, 0, 0, 1],
			depth: 1,
		})

		frame({ image: texture, animate })
	})

	return {
		setAnimate: (_animate: boolean) => {
			animate = _animate ? 1 : 0
		},
	}
}
