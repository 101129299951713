import * as cc from 'creative-tools'
import * as UA from '@urpflanze/animation'

let W = window.innerWidth
let H = window.innerHeight
const c = cc.createCanvas(W, H)
c.id = 'rabbits'
const ctx = c.getContext('2d')!

interface Rabbit {
	img: HTMLImageElement
	pos: [number, number]
	scale: number
	create: number
	dead: boolean
	in_dead: boolean
	lifetime: number
}

let maxRabbitSize = 200
let images: HTMLImageElement[] = []
let rabbits: Rabbit[] = []
let activeRabbit: Rabbit | null

let h = cc.array(8, i => i + 1).map(i => cc.loadImage(`/assets/r${i}.png`)) as Promise<HTMLImageElement>[]
h = [...h, cc.loadImage(`/assets/u1.png`)]

Promise.all(h).then(i => {
	images = i
})

const audio = new Audio('/assets/blop.wav')

function draw(elapsed) {
	ctx.clearRect(0, 0, W, H)

	rabbits.forEach(r => {
		if (r.dead) return
		const fit = cc.fitImage(r.img, r.scale * maxRabbitSize, r.scale * maxRabbitSize, 'contain')

		const elapsed = cc.now() - r.create
		if (!r.in_dead && elapsed > r.lifetime) {
			r.in_dead = true
			kill(r)
		}
		ctx.drawImage(
			r.img,
			fit.x + r.pos[0] * W - fit.width / 2,
			fit.y + r.pos[1] * H - fit.height / 2,
			fit.width,
			fit.height
		)
	})

	updateActiveRabbit()
	itv = requestAnimationFrame(draw)
}

document.getElementById('root')!.appendChild(c)

let itv = 0
export default () => {
	if (itv) {
		cancelAnimationFrame(itv)
	}
	W = window.innerWidth
	H = window.innerHeight
	maxRabbitSize = W > H ? H * 0.4 : W * 0.4

	c.width = W
	c.height = H

	itv = requestAnimationFrame(draw)
}

const touchSupported = 'ontouchstart' in window || (window.Touch && document instanceof Touch)

if (touchSupported) {
	document.addEventListener('touchstart', e => {
		add(e.touches[0].clientX / W, e.touches[0].clientY / H)
	})
	document.addEventListener('touchmove', e => {
		const pos: [number, number] = [e.touches[0].clientX / W, e.touches[0].clientY / H]

		if (activeRabbit) {
			activeRabbit.pos = pos
		}
	})
	document.addEventListener('touchend', e => {
		if (activeRabbit) {
			animate(activeRabbit)
			activeRabbit = null
			audio.play()
		}
	})
} else {
	document.addEventListener('mousedown', e => {
		add(e.clientX / W, e.clientY / H)
	})

	document.addEventListener('mousemove', e => {
		const pos: [number, number] = [e.clientX / W, e.clientY / H]

		if (activeRabbit) {
			activeRabbit.pos = pos
		}
	})

	document.addEventListener('mouseup', e => {
		if (activeRabbit) {
			animate(activeRabbit)
			activeRabbit = null
			audio.play()
		}
	})
}

export function add(x, y, s = 0) {
	const img = cc.randomElement(images)
	activeRabbit = {
		img,
		pos: [x, y],
		scale: s,
		create: cc.now(),
		dead: false,
		in_dead: false,
		lifetime: cc.random('seed', 1000, 10000),
	}
	rabbits.push(activeRabbit)
}

function updateActiveRabbit() {
	if (activeRabbit) {
		activeRabbit.scale = cc.clamp01((cc.now() - activeRabbit.create) / 1000) * 0.86
	}
}

function animate(r) {
	const anim = UA.createAnimation({
		from: r.scale,
		to: r.scale + 0.3,
		duration: 1000,
		interpolator: {
			type: 'elasticOut',
			params: {
				amplitude: 1.4,
				period: 0.38,
			},
		},
	})!
	const s = cc.now()
	function c() {
		anim.update(cc.now() - s)

		r.scale = anim.value

		if (!anim.ended) requestAnimationFrame(c)
		else {
			audio.pause()
			audio.currentTime = 0
		}
	}

	requestAnimationFrame(c)
}

function kill(r) {
	const anim = UA.createAnimation({
		from: r.scale,
		to: 0,
		duration: 500,
		interpolator: 'exponentialOut',
	})!

	const s = cc.now()
	function c() {
		anim.update(cc.now() - s)

		r.scale = anim.value

		if (!anim.ended) requestAnimationFrame(c)
		else {
			r.dead = true
		}
	}

	requestAnimationFrame(c)
}
