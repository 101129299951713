import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, setCurrentScreen } from 'firebase/analytics'
import rabbits, { add } from './rabbits'
import regl from './regl'
import * as cc from 'creative-tools'
import * as UA from '@urpflanze/animation'
import { sgw_live } from './sgw-live'

const firebaseConfig = {
	apiKey: 'AIzaSyA1oB4fowaGQux0LwlXutZejLz71RcWTdI',
	authDomain: 'benn-rip.firebaseapp.com',
	projectId: 'benn-rip',
	storageBucket: 'benn-rip.appspot.com',
	messagingSenderId: '83579047226',
	appId: '1:83579047226:web:2af58f25aecaa5de7e9403',
	measurementId: 'G-47SL2XEL42',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const path = window.location.pathname

const qr = `/share/qr/ait3112`
const LATEST_RELEASE = `/l`

logEvent(analytics, 'page_view', {
	page_location: window.location.hash,
	page_path: path,
	page_title: qr === path ? 'BENN QR' : 'BENN',
})

window.addEventListener('resize', init)
const cursor = document.getElementById('cursor')
cursor!.style.transform = `translate(${window.innerWidth / 2 - 8}px, ${window.innerHeight / 1.5 - 8}px)`
document.addEventListener('mousemove', e => {
	cursor!.style.transform = `translate(${e.clientX - 8}px, ${e.clientY - 8}px)`
})

function init() {
	rabbits()
	regl(path === qr || path === LATEST_RELEASE)
	sgw_live()
}

setTimeout(() => {
	init()

	if (path === qr || path === LATEST_RELEASE) {
		setTimeout(() => {
			window.location.href = 'https://youtu.be/boJqmrYgn80'
		}, 1600)
	}
}, 500)

let marekCount = cc.random(10, 30)
document.addEventListener('click', () => {
	if (marekCount <= 0) {
		appendMarek()

		marekCount = cc.random(10, 30)
	}

	marekCount--
})

const marek = document.createElement('video')
marek.setAttribute('src', 'assets/marek.mp4')
marek.setAttribute('id', 'marek')
marek.setAttribute('muted', 'muted')
document.body.appendChild(marek)

function appendMarek() {
	marek.style.opacity = '0'
	marek.style.transform = 'translate(-50%, -50%) scale(.8)'
	marek.pause()
	marek.currentTime = 0
	setTimeout(() => {
		marek.play()
		marek.style.opacity = '1'
		animate(
			[0, 0.8],
			[1, 1],
			1000,
			false,
			s => {
				console.log(s)
				marek.style.transform = `translate(-50%, -50%) scale(${s[1]})`
			},
			() => {
				setTimeout(() => {
					animate(
						[1, 1],
						[0, 0],
						300,
						true,
						s => {
							marek.style.opacity = s[0] + ''
							marek.style.transform = `translate(-50%, -50%) scaleY(${s[1]})`
						},
						() => {
							marek.pause()
							marek.currentTime = 0
						}
					)
				}, 4000)
			}
		)
	}, 0)
}

function animate(
	from: any,
	to: any,
	duration: number,
	linear: boolean,
	cb: (v: any) => void,
	end: CallableFunction | null = null
) {
	const anim = UA.createAnimation({
		from,
		to,
		duration,
		interpolator: linear
			? 'linear'
			: {
					type: 'elasticOut',
					params: {
						amplitude: 2.6,
						period: 1.2,
					},
			  },
	})!

	const s = cc.now()
	function c() {
		anim.update(cc.now() - s)

		cb(anim.value as any)

		if (!anim.ended) requestAnimationFrame(c)
		else {
			end && end()
		}
	}

	requestAnimationFrame(c)
}
