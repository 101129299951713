import * as cc from 'creative-tools'
import frag from './shader.frag'

const plane = require('primitive-plane')(2, 2, 20, 20)
// const plane = require('primitive-plane')(1, 1, 2, 2, { quads: true })

const canvas = cc.createCanvas(window.innerWidth, window.innerHeight)
canvas.id = 'andareintv'
document.getElementById('root')?.appendChild(canvas)

const regl = require('regl')(canvas, {
	pixelRatio: Math.min(window.devicePixelRatio, 1.5),
	attributes: {
		antialias: false,
		stencil: false,
		alpha: true,
		depth: true,
	},
})

const frame = regl({
	frag,

	vert: `
    precision highp float;
    attribute vec3 position;
    attribute vec2 uv;
    varying vec2 vUv;

    void main() {
        vUv = uv;
        gl_Position =  vec4(position, 1);
    }`,

	uniforms: {
		time: ({ time }) => time,
		image: regl.prop('image'),
		mouseCoords: regl.prop('mouseCoords'),
	},

	attributes: {
		position: plane.positions,
		normal: plane.normals,
		uv: plane.uvs,
	},
	elements: plane.cells,
})

let init = false
let wbs = false
export default _wbs => {
	wbs = _wbs
	createImage()

	if (!init) {
		init = true

		regl.frame(function () {
			regl.clear({
				color: [0, 0, 0, 1],
				depth: 1,
			})
			if (image) {
				frame({ image, mouseCoords })
			}
		})
	}
}

document.addEventListener('mousemove', e => {
	mouseCoords = [e.clientX / window.innerWidth, 1 - e.clientY / window.innerHeight]
})

let mouseCoords = [0.5, 0.5]
let image = null
function createImage() {
	let W = window.innerWidth
	let H = window.innerHeight

	canvas.style.width = W + 'px'
	canvas.style.height = H + 'px'
	canvas.width = W
	canvas.height = H

	const c = cc.createCanvas(W, H)
	c.style.left = '0'
	const ctx = c.getContext('2d', { alpha: false })!
	ctx.fillStyle = '#fff'
	const fontFamily = 'Shlop'
	let fontSize = 1
	const S = W > H ? W * 0.6 : W * 0.7
	while (getTextWidth(ctx, W > H ? 'Andare in TV' : 'Andare', fontSize + 'px ' + fontFamily) < S) {
		fontSize = fontSize += 1
	}
	ctx.font = fontSize + 'px Shlop'
	ctx.textAlign = 'center'
	if (wbs) {
		const height = getTextHeight(ctx, "I'll see", fontFamily)

		ctx.fillText("I'll see", W / 2, H / 2 - height / 4)
		ctx.font = fontSize * 0.78 + 'px Shlop'
		ctx.fillText('you soon', W / 2 + fontSize * 0.02, H / 2 + height * 0.68)
	} else {
		if (W > H) {
			const height = getTextHeight(ctx, 'Andare in TV', fontFamily)
			ctx.fillText('Andare in TV', W / 2, H / 2 + height / 4)
			ctx.font = fontSize * 0.2 + 'px Shlop'
			// ctx.fillText(' 31 OTTOBRE', W / 2, H / 2 + height * 0.7)
		} else {
			const height = getTextHeight(ctx, 'Andare', fontFamily)
			ctx.fillText('Andare', W / 2, H / 2 - height / 4)
			ctx.fillText('in TV', W / 2, H / 2 + height * 0.9)
			ctx.font = fontSize * 0.3 + 'px Shlop'
			// ctx.fillText(' 31 OTTOBRE', W / 2, H / 2 + height * 1.8)
		}
	}
	const img = new Image()
	img.addEventListener('load', () => {
		image = regl.texture({ data: img, flipY: true })
	})
	img.src = c.toDataURL('image/png')
}

const getTextWidth = (ctx, text, font) => {
	ctx.font = font
	return ctx.measureText(text).width
}
const getTextHeight = (ctx, text, font) => {
	ctx.font = font
	let metrics = ctx.measureText(text)
	let fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent
	let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
	return actualHeight
}
